import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

export const Link = styled.a`
    cursor: pointer;
    display: block;
    position: absolute;
    bottom: 5.8%;
    left: 8.5%;
    width: 24.5%;
    height: 12%;
    border-radius: 35px;
    &:hover {
        opacity: 0.15;
        background-color: gray;
    }
`;
