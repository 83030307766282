import { INotificationContentProps } from "../../.";
import { Img } from "../../banner/styled";
import { BannerPopupData } from "../../banner/banner-popup-data";
import questionnaireRuSrc from "assets/images/banner-modal/1x-casino-partners_ru.png";
import questionnaireEnSrc from "assets/images/banner-modal/1x-casino-partners_en.png";
import { Container, Link } from "./styled";

interface IProps extends INotificationContentProps {
    data: BannerPopupData;
}

export const PartnerBanner = ({ data }: IProps) => {
    const countryCode = data.data.currentGeoCountryCode || "";
    const crsImg =
        countryCode === "RU" ? questionnaireRuSrc : questionnaireEnSrc;
    return (
        <Container>
            <Img src={crsImg} loading="lazy" />
            <Link href="https://bit.ly/47vU9de" target="_blank"></Link>
        </Container>
    );
};
