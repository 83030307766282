import { Img, LinkBanner } from "./styled";
import { INotificationContentProps } from "../../index";
import { BannerPopupData } from "../../banner/banner-popup-data";

const links = {
    TW: "https://www.instagram.com/1xaffiliates_taiwan/",
    MO: "https://www.instagram.com/1xaffiliates_macau/",
    MY: "https://www.instagram.com/1xaffiliates_malaysia/",
    ID: "https://www.instagram.com/1xaffiliates_indonesia_/",
    PH: "https://www.instagram.com/1xaffiliates_philippines/",
    JP: "https://www.instagram.com/1xaffiliates_japan/",
    HK: "https://www.instagram.com/1xaffiliates_hongkong/",
    KR: "https://www.instagram.com/1xaffiliates_southkorea/",
    SG: "https://www.instagram.com/1xaffiliates_singapore/",
    TH: "https://www.instagram.com/1xaffiliates_thailand/",
    KH: "https://www.instagram.com/1xaffiliates_cambodia",
    MM: "https://www.instagram.com/1xaffiliates_myanmar/",
    CN: "https://www.instagram.com/1x_affiliates_china/",
    VN: "https://www.instagram.com/1xaffiliates_vietnam/",
};

interface IProps extends INotificationContentProps {
    data: BannerPopupData;
}

export const InstagramBanner = ({ data }: IProps) => {
    const countryCode = data.data.currentGeoCountryCode || "";

    return (
        <LinkBanner
            href={links[countryCode as keyof typeof links]}
            target="__blank"
        >
            <Img
                src={`assets/images/banner-modal/1_1/${countryCode}_instagram.jpg`}
                loading="lazy"
            />
        </LinkBanner>
    );
};
